import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from "firebase/auth"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/api-keys',
      name: 'api-keys',
      component: () => import('@/views/ApiKeys.vue'),
      meta: {
        pageTitle: 'API Keys',
        breadcrumb: [
          {
            text: 'API Keys',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/Subscription.vue'),
      meta: {
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scheduled-notifications',
      name: 'scheduled-notifications',
      component: () => import('@/views/ScheduledNotifications.vue'),
      meta: {
        pageTitle: 'Scheduled Notifications',
        breadcrumb: [
          {
            text: 'Scheduled Notifications',
          }
        ],
      },
    },
    {
      path: '/scheduled-notifications/:app',
      name: 'scheduled-notifications',
      component: () => import('@/views/ScheduledNotifications.vue'),
      meta: {
        pageTitle: 'Scheduled Notifications',
        breadcrumb: [
          {
            text: 'Scheduled Notifications',
          }
        ],
      },
    },
    {
      path: '/email-templates',
      name: 'email-templates',
      component: () => import('@/views/EmailTemplates.vue'),
      meta: {
        pageTitle: 'E-Mail Templates',
        breadcrumb: [
          {
            text: 'E-Mail Templates',
          }
        ],
      },
    },
    {
      path: '/email-templates/new',
      name: 'email-templates-new',
      component: () => import('@/views/EditEmailTemplate.vue'),
      meta: {
        pageTitle: 'E-Mail Templates',
        breadcrumb: [
          {
            text: 'E-Mail Templates',
            to: "/email-templates",
          },
          {
            text: 'Edit Template',
            active: true,
          },
        ],
      },
    },
    {
      path: '/email-templates/:id',
      name: 'email-templates-edit',
      component: () => import('@/views/EditEmailTemplate.vue'),
      meta: {
        pageTitle: 'Templates',
        breadcrumb: [
          {
            text: 'E-Mail Templates',
            to: "/email-templates",
          },
          {
            text: 'Edit Template',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings/:app',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const currentUser = () => {
	return new Promise((resolve, reject) => {
			const unsubscribe = getAuth().onAuthStateChanged(user => {
					unsubscribe();
					resolve(user);
			}, reject);
	})
}

// Authentication guards or redirects
router.beforeEach(async (to, from, next) => {
	const isAuthenticated = (await currentUser()) !== null

	if (!isAuthenticated && (to.name !== "login" && to.name !== "register" && to.name !== "forgot-password")) {
			next({ name: "login" });
	} else if (isAuthenticated && (to.name === "login" || to.name === "register" || to.name === "forgot-password")) {
			next({ name: "home" });
	} else {
			next();
	}
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
