import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import firebase from 'firebase/compat/app'
import { getAuth, getIdToken } from 'firebase/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import axios from "axios"
import { doc, getDoc } from '@firebase/firestore';

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const firebaseConfig = process.env.VUE_APP_ENV === 'test' ? {
  apiKey: "AIzaSyCiTsSDEVJB-6Nyau77PDzPe1lsryDLL0Q",
  authDomain: "abracadalo-test.firebaseapp.com",
  projectId: "abracadalo-test",
  storageBucket: "abracadalo-test.appspot.com",
  messagingSenderId: "187728329204",
  appId: "1:187728329204:web:d13cbaedc9722b8159e1a1"
} : {
  apiKey: "AIzaSyBN5DFDZsdL7HaQ2zmAXKRSHuRWnNkA5P4",
  authDomain: "abracadalo-aabd3.firebaseapp.com",
  projectId: "abracadalo-aabd3",
  storageBucket: "abracadalo-aabd3.appspot.com",
  messagingSenderId: "432982166800",
  appId: "1:432982166800:web:6ff19e988b539d9e1ca995",
  measurementId: "G-NFBM521R21"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
//const analytics = getAnalytics(app);
Vue.prototype.$firestore = firebase.firestore()

getAuth().onAuthStateChanged(async user => {
	if (user === null) return;
	const value = await getDoc(doc(firebase.firestore(), "users", user.uid));
  store.dispatch("auth/fetchUser", {...user, ...value.data()});

	getIdToken(user).then(token => {
		const axiosIns = axios.create({
			baseURL: process.env.VUE_APP_API_BASE_URL,
			headers: {'Authorization': 'Bearer ' + token}
		})

		Vue.prototype.$http = axiosIns
	});
});

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

Vue.use(VueQuillEditor)

new Vue({
  router,
  store,
  firebaseApp,
  render: h => h(App),
}).$mount('#app')
